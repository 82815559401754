<template>
  <div>
    <div class="home">
      <GuestHeader :full-name="user.fullName" :email="user.email" />
      <router-view />
    </div>
    <Footer v-if="!isMobile" class="guest-footer" :transparent-footer="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import GuestHeader from "@/components/organisms/GuestHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import { defineComponent, inject } from "vue";
import storage from "@/lib/storage/storageHelper";
import { useStore } from "vuex";

export default defineComponent({
  name: "GuestContractLayout",
  components: {
    GuestHeader,
    Footer,
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const localStorage = storage.getLocalStorage();
    const store = useStore();
    const guestCode = localStorage.get("guestCode");
    let user;
    if (guestCode) {
      const decode = Buffer.from(guestCode, "base64").toString();
      user = JSON.parse(decode);
      user.fullName = user.lastName + user.firstName;
      if (user.fullName == "undefined") user.fullName = "";
    } else {
      user = store.getters["header/getAccountInfo"];
    }

    return { user, isMobile };
  },
});
</script>

<style lang="scss" scoped>
.home {
  font-weight: 400;
  @include mq(l) {
    height: 100vh;
    min-height: auto;
  }
}
.guest-footer {
  @include mq(l) {
    display: none;
  }
}
</style>
